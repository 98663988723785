/* @font-face {
  font-family: 'Casa Pinada';
  src: url('assets/fonts/CasaPinada.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
} */

@font-face {
  font-family: 'Casa Pinada';
  src: url('assets/fonts/CasaPinada.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Casa Pinada', 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}
